// React
import React from 'react';

// Packages
import t from 'prop-types';
import Image from "next/legacy/image";

// Utilities

import { useLabels } from 'utilities/hooks';

// Components
import WithAuth from 'components/withAuth';
import FullWidthWrapper from 'components/_layout/fullWidthWrapper';
import Button from 'components/button';

const ErrorComponent = () => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const { label } = useLabels();

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <FullWidthWrapper>
            <div className="flex flex-col mb-48 animate-fade-in">
                <div className="flex flex-col items-center justify-center space-y-32">
                    <div className="relative h-[192px] w-full">
                        <Image
                            layout="fill"
                            objectFit="contain"
                            src="https://www.datocms-assets.com/69268/1665403780-404-png.png"
                        />
                    </div>
                    <div className="flex flex-col space-y-16 text-center">
                        <p className="text-blue-100 t-h6">
                            {label('Error404PageHeading')}
                        </p>
                        <p className="text-blue-100 t-caption">
                            {label('Error404PageBody')}
                        </p>
                    </div>
                    <Button variant="primary" action="/">
                        {label('Error404PageButton')}
                    </Button>
                </div>
            </div>
        </FullWidthWrapper>
    );
};

ErrorComponent.propTypes = {
    pageProps: t.object,
};

ErrorComponent.defaultProps = {
    pageProps: {},
};

ErrorComponent.layout = 'default';

export default WithAuth(ErrorComponent);
